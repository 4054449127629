import { AiContentActions } from '../actions/ai-content'
import {
	GET_POSTS_AI_CONTENT_SUCCESS,
	CHANGE_AI_FIELD,
	CHANGE_AI_CREATED_CONTENT,
	CHANGE_AI_HEADLINE_KEYWORD,
	GET_AI_CONTENT_SUCCESS,
	GET_AI_HEADLINES_SUCCESS,
	CHANGE_AI_OPTION_KEYWORD,
	GET_PROJECT_BY_ID_SUCCESS,
	RESET_AI_CONTENT,
} from '../actions/types'
import {
	ECharacteristicOfThePost,
	ECharacteristicOfThePostFinnish,
	ETypeOfPost,
	ETypeOfPostFinnish,
} from '../constants'
import { extractContentHeadlines } from '../helpers/typeDataTransformer'

export type AiTextOptions = {
	keyword: string
	typeOfPost: ETypeOfPost
	characteristicOfThePost: ECharacteristicOfThePost
	feature: string
	customTypeOfPost: string
	systemInput: string
}
export type SomePostSuggestions = {
	topic: string
	typeOfPost: keyof typeof ETypeOfPost
	postCharacteristic: keyof typeof ECharacteristicOfThePost
	postDay: string
	keywords: string[]
}
export type SuggestedContent = {
	content: string
}
export type AiContentState = {
	content: string
	rawHeadlines: string
	headlines: string[]
	headlineKeyword: string
	aiTextOptions: AiTextOptions
	createdBlogContent: string
	somePostSuggestionsOptions: SomePostSuggestions
	somePostSuggestions: SuggestedContent[]
	selectedSomePostIndex: number
	streamText: string
}

export const initialState: AiContentState = {
	content: '',
	// change this to use only the some-post reducer for the some post!
	somePostSuggestionsOptions: {
		topic: '',
		typeOfPost: ETypeOfPost.analytical,
		postCharacteristic: ECharacteristicOfThePost.creative,
		postDay: new Date()
			.toLocaleString('en-us', { weekday: 'long' })
			.toLocaleLowerCase(),
		keywords: [],
	},
	somePostSuggestions: [],
	selectedSomePostIndex: -1,
	rawHeadlines: '',
	headlines: [],
	headlineKeyword: '',
	createdBlogContent: '',
	aiTextOptions: {
		keyword: '',
		typeOfPost: ETypeOfPost.article,
		characteristicOfThePost: ECharacteristicOfThePost.non_specify,
		feature: '',
		customTypeOfPost: '',
		systemInput: '',
	},
	streamText: '',
}

export const aiContentReducer = (
	state = initialState,
	action: AiContentActions,
): typeof initialState => {
	switch (action.type) {
		case CHANGE_AI_FIELD:
			return {
				...state,
				[action.payload.field]: action.payload.value,
			}
		case GET_POSTS_AI_CONTENT_SUCCESS:
			return {
				...state,
				somePostSuggestions: action.payload.data,
			}
		case GET_AI_CONTENT_SUCCESS:
			return {
				...state,
				content:
					action.payload.data &&
					// action.payload.data.content.replace(/<\/?[^>]+(>|$)/g, ''),
					action.payload.data.content,
			}
		case GET_AI_HEADLINES_SUCCESS: {
			let uppercaseKeyword = state.headlineKeyword.substring(0)
			uppercaseKeyword =
				uppercaseKeyword.charAt(0).toUpperCase() + uppercaseKeyword.slice(1)
			return {
				...state,
				rawHeadlines: action.payload.data && action.payload.data.content,
				headlines: extractContentHeadlines(
					action.payload.data && action.payload.data.content,
					uppercaseKeyword,
				),
			}
		}
		case CHANGE_AI_HEADLINE_KEYWORD:
			return { ...state, headlineKeyword: action.payload }
		case CHANGE_AI_OPTION_KEYWORD:
			return {
				...state,
				aiTextOptions: {
					...state.aiTextOptions,
					keyword: action.payload,
				},
			}
		case CHANGE_AI_CREATED_CONTENT:
			return {
				...state,
				createdBlogContent: (state.createdBlogContent || '').concat(
					action.payload,
				),
			}
		case GET_PROJECT_BY_ID_SUCCESS:
		case RESET_AI_CONTENT:
			return { ...initialState }
		default:
			return state
	}
}

export const aiSelectors = {
	getAiContent: (state = initialState): string => state.content,
	getAiBlogContent: (state = initialState): string => state.createdBlogContent,
	getAiHeadlines: (state = initialState): string[] => state.headlines,
	getAiHeadlineKeyword: (state = initialState): string => state.headlineKeyword,
	getAiReducer: (state = initialState): AiContentState => state,
	getAiPosts: (state = initialState): SuggestedContent[] =>
		state.somePostSuggestions,
}
